import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const HeaderPicker = ({ title, selector }) => {
  switch (selector) {
    case 'large':
      return (
        <div
          className="full-width-image-container margin-top-0 custom-header large-header"
        >
          <h2
            className="is-size-1"
            style={{
              color: 'white',
              padding: '1rem',
            }}
          >
            {title}
          </h2>
        </div>
      )
    case 'small':
      return (
        <div
          className="full-width-image-container margin-top-0 custom-header small-header"
        >
          <h2
            className="is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
            style={{
              color: 'white',
              padding: '1rem',
            }}
          >
            {title}
          </h2>
        </div>
      )
    case 'none':
      return (
        <div style={{ padding: "3rem 1.5rem" }}></div>
      );
    default:
      break;
  }

};

export const CustomPageTemplate = ({ title, selector, content, contentComponent }) => {
  const PageContent = contentComponent || Content
  return (
    <div className="content">
      <HeaderPicker title={title} selector={selector} />
      <section className="section section--gradient custom-section">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section custom-subsection">
                {selector === 'none' ? <h2 className="title is-size-3 has-text-weight-bold is-bold-light">{title}</h2> : null}
                <PageContent className="content" content={content} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

CustomPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  selector: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const CustomPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <CustomPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        selector={post.frontmatter.selector}
      />
    </Layout>
  )
}

CustomPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CustomPage

export const customPageQuery = graphql`
  query CustomPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        selector
      }
    }
  }
`
